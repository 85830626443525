const loginEmailInput = document.getElementById("loginEmail");
loginEmailInput.focus();

loginEmailInput.addEventListener("change", (event) => {
    const email = event.target.value;
    if (email.length) {
        loginEmailInput.value = email.trim();
    }
});

const loginEmail = new LiveValidation("loginEmail", { validMessage: " ", onlyOnSubmit: true });
loginEmail.add(Validate.Presence);
loginEmail.add(Validate.Email);

const loginPassword = new LiveValidation("loginPassword", { validMessage: " ", onlyOnSubmit: true });
loginPassword.add(Validate.Presence);

const forgotEmail = new LiveValidation("forgotEmail", { validMessage: " ", onlyOnBlur: true });
forgotEmail.add(Validate.Presence);
forgotEmail.add(Validate.Email);

function loginSubmit(event) {
    event.preventDefault();

    document.querySelector("#loginResult").textContent = "";

    const email = document.querySelector("#loginEmail").value;
    const password = document.querySelector("#loginPassword").value;
    const previousUrl = document.querySelector("#previousUrl").value;

    if (email && password) {
        document.querySelector("#loginButton").textContent = "Checking...";
        document.querySelector("#loginButton").disabled = true;

        const data = new FormData();
        data.append("email", email);
        data.append("password", password);
        data.append("previousUrl", previousUrl);

        fetch("/api/login/", {
            method: "post",
            headers: {
                accept: "application/json",
            },
            body: data,
        })
            .then(function (response) {
                if (!response.ok && response.status === 429) {
                    throw new Error("Too many attempts, please try later.");
                }
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    document.querySelector("#loginResult").textContent = data.error;
                    document.querySelector("#loginButton").disabled = false;
                    document.querySelector("#loginButton").textContent = "Log in";
                } else if (data.success) {
                    window.location.href = `${window.location.origin}${data.success}`;
                } else {
                    throw new Error("Undefined error, please contact support.");
                }
            })
            .catch((error) => {
                document.querySelector("#loginResult").textContent = error;
                document.querySelector("#loginButton").disabled = false;
                document.querySelector("#loginButton").textContent = "Log in";
            });
    }
}

const loginForm = document.getElementById("loginForm");
loginForm.addEventListener("submit", loginSubmit);

document.querySelector("#link-forgot").addEventListener("click", () => {
    document.querySelector(".login-form").style.display = "none";
    document.querySelector(".forgot-form").style.display = "block";
    document.querySelector("#forgotEmail").focus();
});

function forgotSubmit(event) {
    event.preventDefault();

    document.querySelector("#forgotResult").textContent = "";

    const email = document.querySelector("#forgotEmail").value;

    if (email) {
        document.querySelector("#forgotButton").textContent = "Checking...";
        document.querySelector("#forgotButton").disabled = true;

        const data = new FormData();
        data.append("email", email);

        fetch("/api/reset-password/", {
            method: "post",
            body: data,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    document.querySelector("#forgotResult").textContent = data.error;
                } else {
                    document.querySelector("#forgotResult").textContent = data.success;
                }
                document.querySelector("#forgotEmail").value = "";
                document.querySelector("#forgotButton").disabled = false;
                document.querySelector("#forgotButton").textContent = "Send Email";
            })
            .catch((error) => {
                document.querySelector("#forgotEmail").value = "";
                document.querySelector("#forgotResult").textContent = error;
                document.querySelector("#forgotButton").disabled = false;
                document.querySelector("#forgotButton").textContent = "Send Email";
            });
    }
}

const forgotForm = document.getElementById("forgotForm");
forgotForm.addEventListener("submit", forgotSubmit);
